<template>
    <div class="user-job-fair user-content">
        <div class="notice-list" v-if="state">
            <div v-if="jobFairList.length>0" v-loading="isLoading">
                <div class="notice-list-item flex bg-white" v-for="item in jobFairList" :key="item.id">
                    <div class="item-pic flex align-center">
                        <!-- <img :src="item.url"/> -->
                        <el-image
                            style="width: 260px; height: 100px"
                            :src="item.url"
                            fit="contain"
                        ></el-image>
                    </div>
                    <div class="item-info flex">
                        <div class="info-name">{{ item.jobName }}</div>
                        <div class="info-company" v-if="item.companiesNum">已有<span class="warnning">{{ item.companiesNum }}</span>家企业参会，已有<span
                                class="warnning">{{ item.positionNum }}</span>个职位，火热预约中……
                        </div>
                        <div class="info-place">
                            <i class="el-icon-location iconstyle"></i>
                            <span>举办地点：{{ item.holdField }}</span>
                        </div>
                        <div class="info-place info-time">
                            <i class="el-icon-message-solid iconstyle"></i>
                            <span>举办时间：{{ item.holdStartTime }} 至 {{ item.holdEndTime }}</span>
                        </div>
                        <div class="info-place info-bus">
                            <i class="el-icon-info iconstyle"></i>
                            <span>公车路线：{{ item.trafficRoutes }}</span>
                        </div>
                    </div>
                    <div class="item-order">
                        <el-button class="active" @click="routeToJobFairInfo(item.id)">查看招聘会</el-button>
                        <el-button v-if="item.videoUrl" style="margin-left:0; margin-top:8px;" class="active" @click="gotoVideo(item)">回看视频</el-button>
                    </div>
                    <div class="item-icon">
                        <!-- <img src="../../../assets/img/company/fair_3.png" alt=""> -->
                        <!-- 未开始 -->
                        <!-- <img src="../../../assets/img/company/fair_1.png" alt="" v-if="item.status===5"> -->
                        <!-- 已开始 -->
                        <!-- <img src="../../../assets/img/company/fair_2.png" alt="" v-else-if="item.status===3"> -->
                        <!-- 进行中 -->
                        <!-- <img src="../../../assets/img/company/fair_3.png" alt="" v-else-if="item.status===1"> -->
                        <!-- 已结束 -->
                        <!-- <img src="../../../assets/img/company/fair_4.png" alt="" v-else-if="item.status===4"> -->
                        <!-- 取消 -->
                        <!-- <img src="../../../assets/img/company/fair_5.png" alt="" v-else> -->
                    </div>
                </div>
                <app-pagination @handleCurrentChange="handleCurrent" :pageSize="5" :total="total"></app-pagination>
            </div>
            <div class="no" v-else>
                <img src="../../../assets/img/company/loading.png" alt="" srcset="" class="loading-img">
                <p>正在加载中...</p>
            </div>
        </div>
        <div class="no-list flex" v-else>
            <img src="../../../assets/img/users/noJobTip.png" alt="" srcset="">
            <p class="no-tips">亲爱的用户，目前还没有相关新的招聘会信息~~</p>
        </div>
    </div>
</template>

<script>
    import AppPagination from '../../../components/pagination/AppPagination'
    import userRequest from '../../../api/user'

    export default {
        components: {
            AppPagination
        },
        mounted() {
            this.getUserJobFairList()
        },
        data() {
            return {
                state: true,
                total: 5,
                jobFairList: [],
                jobFairForm: { //招聘会搜索条件
                    jobName: '',
                    pageNo: 1,
                    pageSize: 5
                },
                isLoading: false
            }
        },
        methods: {
            gotoVideo(item){
                if(item.videoUrl){
                    window.open(item.videoUrl, '_blank')
                }
            },
            handleCurrent(pageIndex) {
                console.log("当前页", pageIndex)
                // this.jobFairForm.pageNo++;
                this.jobFairForm.pageNo = pageIndex
                this.getUserJobFairList()
            },
            getUserJobFairList() {
                this.isLoading = true
                userRequest.getUserJobFairList(this.jobFairForm).then((res) => {
                    if (res.code === 200) {
                        this.jobFairList = res.data.list
                        this.total = res.data.total
                        if (this.jobFairList.length === 0) {
                            this.status = false
                        }
                    }
                }).catch((err) => {
                    this.status = false
                })
                this.isLoading = false
            },
            routeToJobFairInfo(id) {
                this.$router.push({
                    path: '/user/jobFair-desc',
                    query: {
                        fairId: id
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../assets/css/global.scss";

    .user-job-fair {
        margin-top: 40px;
    }

    .notice-list {
        .notice-list-item {
            position: relative;
            // width: 80%;
            // min-width: 1240px;
            transition: all .3s;
            position: relative;
            padding: 20px;
            // border: 1px dotted #eee;
            border: 1px solid #eee;
            margin-bottom: 15px;
            margin-right: 20px;

            &:hover {
                box-shadow: 1px 3px 3px #f3f3f3;
                // transform: translateY(-3px);
            }

            .item-pic {
                width: 260px;
                height: 120px;
                margin-right: 40px;

                img {
                    width: 180px;
                    height: 180px;
                }
            }

            .item-info {
                // min-width: 70%;
                flex: 1;
                height: 120px;
                overflow: hidden;
                flex-direction: column;
                justify-content: space-between;

                .info-name {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 40px;
                }

                .info-company {
                    color: #666;
                    font-size: 13px;

                    .warnning {
                        color: $main;
                        font-weight: 700;
                    }
                }

                .info-place {
                    margin-top:0px;
                    font-size: 13px;
                    color: #666;
                }

                .info-time {
                    margin-top: 10px;
                }

                .info-bus {
                    margin-top: 10px;
                }
            }

            .item-order {
                width: 150px;
                // position: absolute;
                // top: 50%;
                // right: 2%;
                // transform: translate3d(0, -50%, 0);
                // margin-left: 150px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .el-button {
                    width: 150px;
                    letter-spacing: 2px;

                    &:hover {
                        background: $hover;
                    }
                }
            }

            .item-icon {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }

    .no-list {
        margin: 300px 0 200px 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    @media screen and (max-width: 800px) {
  * {
    box-sizing: border-box;
  }
  .user-content{
      width: 100vw;
  }
}
</style>